import React from 'react';
import CourseItem from '../../components/course/Item';

export default class App extends React.Component {
	constructor(props){
    super(props);
		this.state = {
			course: JSON.parse(props.data.course),
		};
  }

	render () {
		const { course } = this.state;
		return <CourseItem course={course} hasParentContainer={true} />
	}
}
