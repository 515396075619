import React from 'react';
export default function Pin() {
	return (
		<svg id="Atoms_Ikoner_Ui_Thumb-up" data-name="Atoms/Ikoner/Ui/Thumb-up" xmlns="http://www.w3.org/2000/svg" width="21.126" height="19.754" viewBox="0 0 21.126 19.754">
		  <g id="Thumb-up" transform="translate(0 0)">
		    <path id="Combined-Shape" d="M17.748,1.989a2.007,2.007,0,0,1,1.463.629,2.116,2.116,0,0,1,.6,1.5h0v.1L18.767,7.827l2.31-.153a2.116,2.116,0,0,1,1.576.783h0l.085.113a2.108,2.108,0,0,1,.29,1.851h0L20.058,20.431H8.671v1.312H2V8.548H8.671v1.676l7.7-7.693a2.017,2.017,0,0,1,1.223-.537ZM7.261,9.959H3.41V20.332H7.261ZM17.748,3.4a.624.624,0,0,0-.461.211h0L8.671,12.218v6.8H19.005L21.681,10a.705.705,0,0,0-.046-.541h0l-.049-.08a.7.7,0,0,0-.484-.3h0l-4.249.286L18.4,4.028V4.012a.71.71,0,0,0-.142-.344h0l-.065-.076a.606.606,0,0,0-.443-.193Z" transform="translate(-2 -1.989)" fill="#034B45"/>
		  </g>
		</svg>
	);
}
