import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '../../../i18n';

const apps = document.body.querySelectorAll('.course-item-component');
for (var app of apps) {
	ReactDOM.render(
	  <App data={app.dataset} />,
	  app
	);
}
