import React from 'react';

const Btn = React.forwardRef(({ dataset = null, display = 'inline-flex', classes = '', dataType = null, onClick, type = 'button', background = 'bg-transparent', border = 'border-2', color = 'text-accent', href, target = '_self', cta }, ref) => {
 let data = {};
 if (dataset) {
	 for (var d of dataset) {
		 data['data-' + d.name] = JSON.stringify(d.data);
	 }
 }
 if (type === 'button') {
	 return (<button ref={ref} {...data} type={type} data-type={dataType} onClick={onClick} className={`normal-button ${display} px-5 py-3 font-semi-bold ${border} ${color} ${background} ${classes}`}><span className="content">{cta}</span></button>);
 }
 return (<a ref={ref} className={`normal-button ${display} px-5 py-3 font-semi-bold ${border} ${color} ${background} ${classes}`} target={target} href={href}><span className="content">{cta}</span></a>);
});

export default Btn;
