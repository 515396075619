import React from 'react';

export default function Media({ media, objectFit = false, absolute = false }) {
	if (media.kind === 'json') {
		return (<div className="plyr__video-embed videoModulePlayer">
		  <iframe
		    src={media.url + '?loop=1&amp;autoplay=0&amp;muted=0&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media&amp;dnt=true'}
		    allowFullScreen
		    allowtransparency="true"
		    allow="autoplay"
		  ></iframe>
		</div>);
	} else if (media.kind === 'image') {
		return (
			<figure className={`mediacontainer ${absolute ? 'absolutely-positioned' : ''} ${objectFit ? 'object-fit' : ''}`}>
				<picture>
					<img className={`mediacontainer-img ${absolute ? 'absolute' : 'relative'} lazyload`} data-sizes="auto" data-src={'/' + media.url} data-srcset={media.srcset} />
				</picture>
			</figure>
		);

	} else if (media.kind === 'video') {
		return (
			<video className="videoModulePlayer" playsInline controls>
				<source src={'/' + media.url} type="video/mp4" />
			</video>
		);
	} else {
		return <p>Unknown format.</p>;
	}
}
