import React from 'react';
import Button from '../Button';
import Pin from '../../svg/Pin';
import Thumb from '../../svg/Thumb';
import Arrow from '../../svg/Arrow';
import CourseAttraction from './CourseAttraction';
import Media from '../Media';
import Carousel from '../../../components/carousel';
// import InteractableModals from '../../../components/interactableModals';

import { withTranslation } from 'react-i18next';

class Course extends React.Component {
	constructor (props) {
		super(props);
		this.swiperRef = React.createRef();
		// this.mapButtonRef = React.createRef();
		this.carousel = null;
	}

	componentDidMount () {
		const { hasParentContainer = false } = this.props;

		this.carousel = new Carousel({swipers: [this.swiperRef.current]});
		this.carousel.init();

		if (!hasParentContainer) {
			/*
			*	This component is not rendered using the entrypoint in twig (templates/_modules/_courseTileReact), but
			* is rendered from react (react/components/course/List), and InteractableModals not handled.
			*/
			// console.log('Test---------------');
			// const maps = new InteractableModals({triggers: [this.mapButtonRef.current]});
			// maps.init();
		}
	}

	render () {
		const { hasParentContainer = false, skipMargin = false, course, t } = this.props;

		return (
			<div id={`course-tile-${course.id}`} className={`course-item w-full z-0 relative ${hasParentContainer ? '' : `course-tile active ${skipMargin ? '' : 'mb-20'}`}`}>
				<a className="course-overlay-menu" aria-hidden="true" tabIndex="-1" href={course.url}><span className="mb-2 leading-tight text-xxxxsmall font-semi-bold">{t('Go to the course')}</span><Arrow /></a>
				<a data-aos="fade-up" data-aos-offset="0" href={course.url}><h3 className="text-smallm font-semi-bold mb-4 medium:mb-8 underlined-title small-centered">{course.title}</h3></a>
				{course.byline ? <p data-aos="fade-up" data-aos-delay="100" data-aos-offset="0" className="text-center medium:text-left"><span className="font-semi-bold">{t('By')}:</span> {course.byline}</p> : null}
				<div data-aos="fade-up" data-aos-delay="200" data-aos-offset="0" className="block medium:flex flex-row-reverse flex-1 flex-shrink max-w-full min-w-0">
					<div className="-mx-6 medium:mx-0 flex-1 min-w-0">
						<div data-remove-drag-indicator={course.dragIndicatorId} ref={this.swiperRef} className="w-full swiper-container swiper-auto-slides nested-swiper child-nested-swiper react-handled mask-fadeout-right">
							<div className="swiper-wrapper mt-8 mb-12 medium:my-0 flex-shrink">
								{course.attractions && course.attractions.length
									? course.attractions.map((attraction, i) => <CourseAttraction index={i} course={course} key={attraction.id} attraction={attraction} />)
									: null
								}
							</div>
							{course.attractions && course.attractions.length > 1
								? <div className="swiper-pagination mb-1 medium:hidden"></div>
								: null
							}
						</div>
					</div>
					<div className="w-full medium:w-48 medium:mr-12 self-end flex-shrink-0">
						<div className="w-full flex flex-wrap my-4 medium:my-0 justify-center medium:justify-start">
							{this.props.isMedieval ? null : <p className="mb-4 flex-shrink-0 flex mr-4 text-xxxxsmall"><span className="mr-2 icon fill"><Thumb /></span> {course.recommendationCount} {t(parseInt(course.recommendationCount) === 1 ? 'recommendation' : 'recommendations')}</p>}
							<p className="mb-4 flex-shrink-0 flex text-xxxxsmall"><span className="mr-2 icon stroke"><Pin /></span> {course.attractions && course.attractions.length ? course.attractions.length : 0} {t('attractions')}</p>
						</div>
						<div className="flex flex-wrap justify-center medium:justify-start">
							<Button cta={t('View course')} type="link" href={course.url} classes="mr-2" />
							{/* <Button cta={t('Coursemap')} classes="interactable-map" display="inline-flex medium:hidden" dataset={[{name: 'layers', data: course.modalLayers}]} ref={this.mapButtonRef} />
							{/* % include '_components/_buttons/_normal' with { CTA: 'View course'|t, type: 'link', href: course.url, buttonClass: 'mr-2' } %}
							{% include '_components/_buttons/_normal' with { CTA: 'Coursemap'|t, buttonClass: 'interactable-map', display: 'inline-flex medium:hidden', dataset: [{name: 'layers', data: layers}] } % */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(Course);
//
// const Cp = withTranslation()(Course);
//
// export default React.forwardRef((props, ref) => <Cp
//   innerRef={ref} {...props}
// />);
