import React from 'react';
export default function Arrow() {
	return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.503" height="26.773" viewBox="0 0 21.503 26.773">
      <g id="Group_731" data-name="Group 731" transform="translate(-908.097 1772.083) rotate(-90)">
        <g id="Group_219" data-name="Group 219" transform="translate(1745.311 908.097)">
          <path id="Pil_høyre" data-name="Pil høyre" d="M3.072,13.386,13.823,2.975l4.608,4.462-6.144,5.949,6.144,5.949L13.823,23.8ZM21.5,7.437,13.823,0,0,13.386,13.823,26.773l7.68-7.437-6.144-5.949Z" transform="translate(0 21.503) rotate(-90)" fill="#fff" fillRule="evenodd"/>
        </g>
      </g>
    </svg>
	);
}
