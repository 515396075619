import React from 'react';
export default function Pin() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12.44" height="17.313" viewBox="0 0 12.44 17.313">
		  <g id="Component_4_122" data-name="Component 4 – 122" transform="translate(0.75 0.75)">
		    <path id="Path_85" data-name="Path 85" d="M31.169,17.369c4.176,0,5.47,4.067,5.47,5.5a6.616,6.616,0,0,1-.923,2.971l-4.547,6.752-4.547-6.752a6.616,6.616,0,0,1-.923-2.971C25.7,21.436,26.992,17.369,31.169,17.369Z" transform="translate(-25.699 -17.369)" fill="none" stroke="#034b45" strokeMiterlimit="10" strokeWidth="1.5"/>
		    <circle id="Ellipse_34" data-name="Ellipse 34" cx="1.915" cy="1.915" r="1.915" transform="translate(3.555 3.582)" fill="#034b45"/>
		  </g>
		</svg>
	);
}
