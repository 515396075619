import React from 'react';
import Media from '../Media';

export default function CourseAttraction({ attraction, course, index }) {
	return (
		<div className="swiper-slide relative flex flex-col items-center px-6 medium:px-4">
			<a className="text-center" href={course.url} title={course.title}>
				<div className={`course-attraction-img-container w-56 h-56 ${index % 2 == 0 ? '' : 'rounded-full'} bg-secondary overflow-hidden relative text-white text-xxxxsmall`}>
					{attraction.mainMedia
						?	<Media media={attraction.mainMedia} objectFit={true} absolute={true} />
						: null
					}
					<div className={`white-fade-bg absolute bottom-0 left-0 right-0 pt-10 pb-5 ${index % 2 == 0 ? 'px-6' : 'px-12'} w-full z-20`}><span>{attraction.title}</span></div>
				</div>
			</a>
		</div>
	);
}
